<template>
  <div class="language-selector">
    <div v-for="lang in languages" :key="lang.value" 
         class="language-option" 
         :class="{ 'selected': modelValue === lang.value }"
         @click="$emit('update:modelValue', lang.value)">
      <img :src="lang.flag" :alt="lang.label" class="flag-icon" />
    </div>
  </div>
</template>

<script>
import DKImage from "@/assets/countries/DK.png";
import NOImage from "@/assets/countries/NO.png";
import FIImage from "@/assets/countries/FI.png";
import UKImage from "@/assets/countries/UK.png";

export default {
  props: {
    modelValue: {
      type: String,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const languages = [
      { label: 'English', value: 'en', flag: UKImage },
      { label: 'Danish', value: 'da', flag: DKImage },
      { label: 'Finnish', value: 'fi', flag: FIImage },
      { label: 'Norwegian', value: 'no', flag: NOImage }
    ];

    return {
      languages
    };
  }
};
</script>

<style scoped>
.language-selector {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.language-option {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.2s ease;
}

.language-option:hover {
  transform: scale(1.1);
}

.language-option.selected {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px var(--primary-color);
}

.flag-icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style> 