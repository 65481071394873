<template>
  <div>
    <div class="surface-section p-6 pt-0 pb-0">
      <div class="flex">
        <div style="flex: 1">
          <div class="font-bold text-900 text-2xl mt-5 mb-2">
            {{ pageTitle }}
          </div>
          <div class="text-700 mb-5 line-height-3 mb-5">
            {{ pageDescription }}.
          </div>
        </div>
        <div class="flex justify-content-end align-items-center">
          <Button
            v-if="activeTab < 6"
            :disabled="!appContentUpdated"
            @click="createOrUpdateEntity"
            :label="'Save ' + entityName"
            icon="pi pi-cloud-upload"
          />
        </div>
      </div>
    </div>

    <Loading
      v-if="loading"
      class="mt-22"
      :title="'Loading App Content Configurations'"
      :full="false"
    />

    <div v-else class="w-full">
      <TabView v-model:activeIndex="activeTab">
        <TabPanel header="Limitations">
          <div class="px-6 w-full">
            <p class="font-bold text-900 text-lg m-0" for="limitationInfo">
              Limitations info
            </p>
            <p
              class="m-0 text-sm line-height-2 text-700 mb-3"
              for="limitationInfo"
            >
              Information about the memberships limitations
            </p>
            <div class="mb-3">
              <LanguageSelector v-model="selectedLanguage" />
            </div>
            <HtmlEditor
              id="limitationInfo"
              name="limitationInfo"
              :showHeaders="true"
              @update:modelValue="
                (value) => updateTranslation('limitationsInfoText', value)
              "
              :modelValue="getTranslation('limitationsInfoText')"
              :editButton="true"
            />
          </div>
        </TabPanel>
        <TabPanel header="Membership">
          <div class="px-6 w-full">
            <p class="font-bold text-900 text-lg m-0">Membership info</p>
            <p class="m-0 text-sm text-700 mb-3">Summary of the membership</p>
            <div class="mb-3">
              <LanguageSelector v-model="selectedLanguage" />
            </div>
            <HtmlEditor
              id="membershipInfo"
              name="membershipInfo"
              :showHeaders="true"
              @update:modelValue="
                (value) => updateTranslation('membershipInfoText', value)
              "
              :modelValue="getTranslation('membershipInfoText')"
              :editButton="true"
            />
          </div>
        </TabPanel>
        <TabPanel header="Upgrade membership explainer">
          <div class="px-6 w-full">
            <p class="font-bold text-900 text-lg m-0">Upgrade membership explainer</p>
            <p class="m-0 text-sm text-700 mb-3">Information about upgrading membership</p>
            <div class="mb-3">
              <LanguageSelector v-model="selectedLanguage" />
            </div>
            <HtmlEditor
              id="upgradeMembershipInfo"
              name="upgradeMembershipInfo"
              :showHeaders="true"
              @update:modelValue="
                (value) => updateTranslation('upgradeMembershipExplainerText', value)
              "
              :modelValue="getTranslation('upgradeMembershipExplainerText')"
              :editButton="true"
            />
          </div>
        </TabPanel>
        <TabPanel header="Cancel membership explainer">
          <div class="px-6 w-full">
            <p class="font-bold text-900 text-lg m-0">Cancel membership explainer</p>
            <p class="m-0 text-sm text-700 mb-3">Information about cancelling membership</p>
            <div class="mb-3">
              <LanguageSelector v-model="selectedLanguage" />
            </div>
            <HtmlEditor
              id="cancelMembershipInfo"
              name="cancelMembershipInfo"
              :showHeaders="true"
              @update:modelValue="
                (value) => updateTranslation('cancelMembershipExplainerText', value)
              "
              :modelValue="getTranslation('cancelMembershipExplainerText')"
              :editButton="true"
            />
          </div>
        </TabPanel>
        <TabPanel header="Membership benefits">
          <div class="px-6 w-full">
            <p class="font-bold text-900 text-lg m-0">Membership benefits</p>
            <p class="m-0 text-sm text-700 mb-3">These reasons are shown to the user when they try to cancel membership</p>
            <div class="mb-3">
              <LanguageSelector v-model="selectedLanguage" />
            </div>
            <HtmlEditor
              id="cancelReasonsInfo"
              name="cancelReasonsInfo"
              :showHeaders="true"
              @update:modelValue="
                (value) => updateTranslation('cancelMembershipReasonsText', value)
              "
              :modelValue="getTranslation('cancelMembershipReasonsText')"
              :editButton="true"
            />
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
<script>
import { onMounted, ref } from "vue";
import HtmlEditor from "../../components/HtmlEditor.vue";
import LanguageSelector from "../../components/LanguageSelector.vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

export default {
  components: {
    HtmlEditor,
    LanguageSelector
  },

  setup() {
    const storeActionGetEntity = "appContents/getAppContent";
    const storeActionUpdateEntity = "appContents/update";
    const storeActionCreateEntity = "appContents/create";

    const storeGetterGetEntity = "appContents/appContent";

    const entityName = "App Content";
    const pageTitle = "App Content";
    const pageDescription = "Here you can manage the App contents";

    const store = useStore();
    const toast = useToast();

    const loading = ref(false);
    const activeTab = ref(0);
    const selectedLanguage = ref("en");

    const languages = [
      { label: "English", value: "en" },
      { label: "Danish", value: "da" },
      { label: "Finnish", value: "fi" },
      { label: "Norwegian", value: "no" },
    ];

    const appContent = ref();

    const editedAppContent = ref({
      limitationsInfoText: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" },
        ],
      },
      membershipInfoText: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" },
        ],
      },
      upgradeMembershipExplainerText: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" },
        ],
      },
      cancelMembershipExplainerText: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" },
        ],
      },
      cancelMembershipReasonsText: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" },
        ],
      },
    });

    const appContentUpdated = ref(false);

    function getTranslation(field) {
      const translation = editedAppContent.value[field].translations.find(
        (t) => t.languageCode === selectedLanguage.value
      );
      return translation ? translation.text : "";
    }

    function updateTranslation(field, value) {
      const translation = editedAppContent.value[field].translations.find(
        (t) => t.languageCode === selectedLanguage.value
      );
      if (translation) {
        translation.text = value;
      }
      appContentUpdated.value = true;
    }

    function createOrUpdateEntity() {
      if (appContent.value == null) {
        createEntity();
      } else {
        updateEntity();
      }
    }

    async function updateEntity() {
      loading.value = true;
      await store
        .dispatch(storeActionUpdateEntity, editedAppContent.value)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "App contents was updated",
            life: 3000,
          });
          appContentUpdated.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get app contents" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    async function createEntity() {
      loading.value = true;
      await store
        .dispatch(storeActionCreateEntity, editedAppContent.value)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "App content configurations was created",
            life: 3000,
          });
          appContentUpdated.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not create app contents" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    async function loadRequiredData() {
      loading.value = true;
      await store
        .dispatch(storeActionGetEntity)
        .then(() => {
          const loadedAppContent = store.getters[storeGetterGetEntity];
          if (loadedAppContent == null) {
            return;
          }
          appContent.value = loadedAppContent;
          editedAppContent.value = loadedAppContent;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get app contents" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    onMounted(() => {
      loadRequiredData();
    });

    return {
      entityName,
      pageTitle,
      pageDescription,

      editedAppContent,
      appContentUpdated,
      loading,
      activeTab,
      selectedLanguage,
      languages,

      createOrUpdateEntity,
      updateTranslation,
      getTranslation,
    };
  },
};
</script>
