<template>
  <Sidebar
    v-model:visible="createNewSidebarVisible"
    class="p-sidebar-lg"
    position="right"
    :showCloseIcon="false"
  >
    <Loading
      v-if="creating"
      :title="'Creating ' + entityName + '...'"
      :full="false"
    />
    <div v-else>
      <div class="flex">
        <h3>Create {{ entityName }}</h3>
        <Button
          @click="gamePreviewVisible = true"
          type="button"
          icon="pi pi-eye"
          class="ml-auto btn-xs my-auto"
          label="Preview"
          style="height: 2rem"
        ></Button>
      </div>
      <Form
        :fields="createFields"
        :context="newEntity"
        @onChange="updatePreviewEntity"
        @onAction="createEntity"
        :actionLabel="'Create'"
        :actionIcon="'pi pi-plus-circle'"
      />
    </div>
  </Sidebar>
  <Sidebar
    v-model:visible="updateSidebarVisible"
    class="p-sidebar-lg"
    position="right"
    :showCloseIcon="false"
  >
    <Loading
      v-if="creating"
      :title="'Creating ' + entityName + '...'"
      :full="false"
    />
    <div v-else>
      <div class="flex">
        <h3>Update {{ entityName }}</h3>
        <Button
          @click="gamePreviewVisible = true"
          type="button"
          icon="pi pi-eye"
          class="ml-auto btn-xs my-auto"
          label="Preview"
          style="height: 2rem"
        ></Button>
      </div>
      <Form
        :fields="updateFields"
        :context="selectedEntity"
        @onChange="updatePreviewEntity"
        @onAction="updateEntity"
        :actionLabel="'Update'"
        :actionIcon="'pi pi-cloud'"
      />
    </div>
  </Sidebar>

  <Dialog
    v-model:visible="gamePreviewVisible"
    :draggable="false"
    modal
    header="Header"
    :style="{ width: '40rem' }"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <h3 class="font-bold white-space-nowrap m-0 p-0">Game preview</h3>
      </div>
    </template>
    <div class="flex justify-content-center align-items-center">
      <div>
        <div
          :style="
            'width: 395px; height: 115px;' +
            'background-image: url(' +
            previewEntity?.coverImageUrl +
            ')'
          "
          class="bg-cover bg-center bg-no-repeat bg-gray-500 border-round-lg"
        ></div>
        <h4 class="p-0 m-0 text-blue-800">{{ previewEntity?.titleLocalized?.translations?.find(t => t.languageCode === 'en')?.text }}</h4>
        <p class="p-0 m-0 text-xs text-blue-800">
          {{ previewEntity?.descriptionLocalized?.translations?.find(t => t.languageCode === 'en')?.text }}
        </p>
      </div>
    </div>
  </Dialog>

  <Dialog
    v-model:visible="enableAppGameMenuVisible"
    :draggable="false"
    modal
    header="Header"
    :style="{ width: '40rem' }"
  >
    <template #header>
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <h3 class="font-bold white-space-nowrap m-0 p-0">
          {{ appGamesMenuEnabled ? "Disable" : "Enable" }} App games menu
        </h3>
      </div>
    </template>
    <div class="text-center">
      <p>
        You are about to
        <b>{{ appGamesMenuEnabled ? "Disable" : "Enable" }}</b> the App games
        menu in the app
      </p>
      <p>Pleas confirm your action</p>
      <Button
        @click="updateAppGamesMenuEnabled(!appGamesMenuEnabled)"
        class="px-8 mt-4"
        >{{ appGamesMenuEnabled ? "Disable" : "Enable" }}</Button
      >
    </div>
  </Dialog>

  <div class="surface-section p-6 pt-0">
    <div class="flex">
      <div style="flex: 1">
        <div class="font-bold text-900 text-2xl mt-5 mb-2">
          {{ pageTitle }}
          <Chip
            v-tooltip="
              `App games menu is ${
                appGamesMenuEnabled ? 'enabled' : 'disabled'
              } in the App`
            "
            :class="appGamesMenuEnabled ? 'bg-green-400' : 'bg-orange-400'"
            class="text-white px-3"
            style="height: 1.5rem"
            :label="appGamesMenuEnabled ? 'Enabled' : 'Disabled'"
          />
        </div>
        <div class="text-700 mb-5 line-height-3 mb-5">
          {{ pageDescription }}.
        </div>
      </div>
      <div class="flex justify-content-end gap-2 align-items-center">
        <Button
          @click="showCreate"
          :label="'New ' + entityName"
          icon="pi pi-plus-circle"
        />
        <Button
          @click="enableAppGameMenuVisible = true"
          :class="
            !appGamesMenuEnabled
              ? 'bg-green-400 border-green-400'
              : 'bg-orange-400 border-orange-400'
          "
          :label="appGamesMenuEnabled ? 'Disable' : 'Enable'"
          :icon="`pi ${appGamesMenuEnabled ? 'pi-stop' : 'pi-play'}`"
          v-tooltip="
            `${
              !appGamesMenuEnabled ? 'Enable' : 'Disable'
            } the app games menu in the App`
          "
        />
      </div>
    </div>

    <Loading v-if="loading" :title="'Loading App games'" :full="false" />
    <div v-else>
      <DataTable
        @row-click="onRowClicked"
        :value="appGames"
        dataKey="id"
        class="p-datatable-lg"
        :rowHover="true"
        responsiveLayout="scroll"
      >
        <template #empty> No {{ pageTitle }} found. </template>
        <template #loading> Loading {{ pageTitle }}. Please wait. </template>
        <Column field="coverImageUrl" header="Cover image">
          <template #body="{ data }">
            <img :src="data.coverImageUrl" style="width: 6rem" />
          </template>
        </Column>

        <Column field="title" header="Title"></Column>
        <Column field="description" header="Description"></Column>

        <Column header="Status">
          <template #body="{ data }">
            <Chip
              class="px-3 p-2 font-bold text-xs text-white"
              style="background: orange"
              v-if="!data.enabled"
              >Disabled</Chip
            >
            <Chip
              class="px-3 p-2 font-bold text-white text-xs"
              style="background: LimeGreen"
              v-else
              >Enabled</Chip
            >
          </template>
        </Column>
        <Column
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible"
        >
          <template #body="{ data }">
            <div class="flex gap-2">
              <Button
                @click="deleteEntity(data.id)"
                type="button"
                icon="pi pi-trash"
              ></Button>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { uploadFile } from "@/services/fileUploadService";

export default {
  components: {
  },
  setup() {
    const storeActionGetEntities = "appGames/getAppGameListItems";
    const storeActionEnsureEntities = "appGames/ensureAppGameListItems";

    const storeGetterGetEntities = "appGames/appGameListItems";
    const storeActionCreateEntity = "appGames/createAppGame";
    const storeActionDeleteEntity = "appGames/deleteAppGame";
    const storeActionUpdateEntity = "appGames/updateAppGame";
    const storeActionUpdateAppGamesEnabled = "updateAppGamesMenuEnabled";

    const entityName = "App Game";
    const pageTitle = "App Games";
    const pageDescription = "Here you can manage your App Games";

    const store = useStore();
    const toast = useToast();

    const loading = ref(false);
    const creating = ref(false);

    const appGames = ref([]);

    const createNewSidebarVisible = ref(false);
    const updateSidebarVisible = ref(false);
    const gamePreviewVisible = ref(false);
    const enableAppGameMenuVisible = ref(false);

    const activeCountryCode = computed(() => {
      var activeAccountCountry = store.getters["activeAccountCountry"];
      var countryAccounts = store.getters["countryAccounts"];
      return countryAccounts?.find((x) => x.id == activeAccountCountry)
        ?.countryCode;
    });
    const appGamesMenuEnabled = computed(() => {
      var activeAccountCountry = store.getters["activeAccountCountry"];
      var countryAccounts = store.getters["countryAccounts"];
      return countryAccounts?.find((x) => x.id == activeAccountCountry)
        ?.appGamesEnabled;
    });

    const appGameTypes = [
      { title: "General", id: "General" },
      { title: "Staff only", id: "StaffOnly" },
      { title: "Staff excluded", id: "StaffExcluded" },
      { title: "Members only", id: "MembersOnly" },
    ];
    const newEntity = ref({
      titleLocalized: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" }
        ]
      },
      descriptionLocalized: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" }
        ]
      },
      url: "",
      coverImageUrl: "",
      appGameType: "General",
    });

    const selectedEntity = ref();
    const previewEntity = ref({
      titleLocalized: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" }
        ]
      },
      descriptionLocalized: {
        translations: [
          { languageCode: "en", text: "" },
          { languageCode: "da", text: "" },
          { languageCode: "fi", text: "" },
          { languageCode: "no", text: "" }
        ]
      },
      url: "",
      coverImageUrl: "",
    });

    const createFields = ref([
      {
        id: "titleLocalized",
        type: "localized-text",
        title: "Title",
        help: "Name of the App game",
      },
      {
        id: "descriptionLocalized",
        type: "localized-text",
        title: "Description",
        help: "Description the App game",
      },
      {
        id: "coverImageUrl",
        type: "imageUpload",
        title: "Cover image",
        help: "Image that will be shown. Dimensions of 315 x 115 will be fully visible. Try to keep below 300kb in size",
      },
      {
        id: "appGameType",
        type: "dropdown",
        config: {
          options: appGameTypes,
          optionLabel: "title",
          optionValue: "id",
          placeholder: "Please select type",
        },
        title: "Type",
        help: "The type specifies which users can see the game",
      },
      {
        id: "url",
        type: "text-input",
        title: "Game Url",
        help: "Url to Scratcher game",
      },
    ]);

    const updateFields = ref([
      {
        id: "titleLocalized",
        type: "localized-text",
        title: "Title",
        help: "Name of the App game",
      },
      {
        id: "descriptionLocalized",
        type: "localized-text",
        title: "Description",
        help: "Description the App game",
      },
      {
        id: "url",
        type: "text-input",
        title: "Game Url",
        help: "Url to Scratcher game",
      },
      {
        id: "coverImageUrl",
        type: "imageUpload",
        title: "Cover image",
        help: "Image that will be shown. Dimensions of 315 x 115 will be fully visible. Try to keep below 300kb in size",
      },
      {
        id: "appGameType",
        type: "dropdown",
        config: {
          options: appGameTypes,
          optionLabel: "title",
          optionValue: "id",
          placeholder: "Please select type",
        },
        title: "Type",
        help: "The type specifies which users can see the game",
      },
      {
        id: "enabled",
        type: "switch",
        title: "Is Enabled",
      },
    ]);

    function onRowClicked(payload) {
      selectedEntity.value = payload.data;
      previewEntity.value = { ...payload.data };
      updateSidebarVisible.value = true;
    }

    async function createEntity(formContext) {
      creating.value = true;

      var imageUrl = await handleImageUpload(formContext);
      formContext.coverImageUrl = imageUrl;

      await store
        .dispatch(storeActionCreateEntity, formContext)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "App game created",
            life: 3000,
          });
          createNewSidebarVisible.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not create app game" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    async function updateEntity(formContext) {
      creating.value = true;

      var imageUrl = await handleImageUpload(formContext);

      formContext.coverImageUrl = imageUrl;

      await store
        .dispatch(storeActionUpdateEntity, formContext)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "App game updated",
            life: 3000,
          });
          updateSidebarVisible.value = false;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not update app game" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    async function deleteEntity(id) {
      await store
        .dispatch(storeActionDeleteEntity, id)
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "App game deleted",
            life: 3000,
          });
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not delete app game" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (creating.value = false));

      reloadData();
    }

    async function handleImageUpload(formContext) {
      if (formContext.coverImageUrl == selectedEntity.value?.coverImageUrl)
        return formContext.coverImageUrl;

      var imageContainer =
        formContext.titleLocalized.translations[0].text + "-" + activeCountryCode.value + "-images";
      var coverUrl = "";
      if (formContext.coverImageUrl) {
        var logoFile = await uploadFile(
          formContext.coverImageUrl,
          "games",
          imageContainer,
          store
        );
        coverUrl = logoFile.url;
      }
      return coverUrl;
    }

    async function updateAppGamesMenuEnabled(enable) {
      enableAppGameMenuVisible.value = false;
      await store
        .dispatch(storeActionUpdateAppGamesEnabled, enable)
        .then(() => {
          var detail = `The App games menu is now ${
            !enable ? "disabled" : "enabled"
          }`;
          toast.add({
            severity: "success",
            summary: "Success",
            detail: detail,
            life: 3000,
          });
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: err,
            life: 3000,
          });
        });

      await store.dispatch("getCountryAccounts").catch((err) => {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: err,
          life: 3000,
        });
      });

      reloadData();
    }

    async function reloadData() {
      loading.value = true;
      await store
        .dispatch(storeActionGetEntities)
        .then(() => {
          const loadedEntities = store.getters[storeGetterGetEntities];
          appGames.value = loadedEntities;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get app games" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    async function loadRequiredData() {
      loading.value = true;
      await store
        .dispatch(storeActionEnsureEntities)
        .then(() => {
          const loadedEntities = store.getters[storeGetterGetEntities];
          appGames.value = loadedEntities;
        })
        .catch((err) => {
          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Could not get app games" + ": " + err,
            life: 3000,
          });
        })
        .finally(() => (loading.value = false));
    }

    function showCreate() {
      previewEntity.value = { ...newEntity.value };
      createNewSidebarVisible.value = true;
    }

    function updatePreviewEntity(e) {
      var context = { ...e };

      previewEntity.value = context;

      if (
        context.coverImageUrl != selectedEntity.value?.coverImageUrl &&
        context?.coverImageUrl != newEntity.value.coverImageUrl &&
        context?.coverImageUrl != ""
      ) {
        var reader = new FileReader();
        reader.onloadend = function () {
          previewEntity.value.coverImageUrl = reader.result;
        };
        reader.readAsDataURL(previewEntity.value.coverImageUrl);
        return;
      }
    }

    onMounted(() => {
      loadRequiredData();
    });

    return {
      entityName,
      pageTitle,
      pageDescription,

      createNewSidebarVisible,
      updateSidebarVisible,
      gamePreviewVisible,
      enableAppGameMenuVisible,

      updateFields,
      createFields,

      newEntity,
      selectedEntity,
      previewEntity,
      appGameTypes,

      loading,
      creating,

      appGamesMenuEnabled,

      appGames,

      updatePreviewEntity,

      updateAppGamesMenuEnabled,

      createEntity,
      updateEntity,
      deleteEntity,
      onRowClicked,
      showCreate,
    };
  },
};
</script>
