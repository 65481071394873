<template>
  <div v-if="editor">
    <div class="text-200 p-0 flex gap-1">
      <input
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color || '#000000'"
      />

      <button
        v-if="showHeaders"
        class="editorButton font-bold"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{
          'is-active': editor.isActive('heading', { level: 1 }) && !disabled,
        }"
      >
        H1
      </button>
      <button
        v-if="showHeaders"
        class="editorButton font-bold"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{
          'is-active': editor.isActive('heading', { level: 2 }) && !disabled,
        }"
      >
        H2
      </button>
      <button
        v-if="showHeaders"
        class="editorButton font-bold"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{
          'is-active': editor.isActive('heading', { level: 3 }) && !disabled,
        }"
      >
        H3
      </button>
      <button
        class="editorButton"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        <i class="fa fa-minus"></i>
      </button>
      <button
        class="editorButton"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') && !disabled }"
      >
        <i class="fa fa-list"></i>
      </button>
      <button
        class="editorButton bold"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') && !disabled }"
      >
        <i class="fa fa-bold" />
      </button>
      <button
        class="editorButton"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') && !disabled }"
      >
        <i class="fa fa-italic" />
      </button>
      <button
        class="editorButton"
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') && !disabled }"
      >
        <i class="fa fa-underline" />
      </button>
      <Button
        v-if="editButton"
        class="btn btn-xs text-xs ml-auto mb-1 flex gap-2"
        style="font-size: 1rem"
      >
        <i style="font-size: 0.8rem" class="pi pi-pencil"> </i>
        Edit
      </Button>
    </div>
    <div class="flex gap-2">
      <div
        @click="editor.chain().focus()"
        class="p-2 border-300 border-1 mt-1 hover:border-blue-500 w-full border-round editor"
        :class="{ 'bg-gray-300': disabled }"
        style="min-height: 200px"
      >
        <editor-content :disabled="true" :editor="editor" />
      </div>
      <div class="flex gap-2 mb-2 relative my-auto">
        <Button
          type="button"
          class="p-0 flag-button"
          @click.stop="showDropdown = !showDropdown"
        >
          <img
            :src="getFlagImage(currentLanguage)"
            :alt="currentLanguage"
            class="flag-image"
          />
        </Button>
        <div v-show="showDropdown" class="language-dropdown">
          <div
            v-for="option in languageOptions"
            :key="option.label"
            class="dropdown-item"
            @click="selectLanguage(option)"
          >
            <img
              :src="getFlagImage(option.command())"
              :alt="option.command()"
              class="menu-flag"
            />
            <span>{{ option.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { onMounted, onUnmounted, ref, computed } from "vue";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      default: "",
    },
    editButton: {
      default: false,
    },
    showHeaders: {
      default: false,
    },
    blackTextColor: {
      default: false,
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const showDropdown = ref(false);
    const currentLanguage = ref("en");
    const disabled = ref(props.editButton ? true : false);

    const languageOptions = [
      { label: "English", command: () => "en" },
      { label: "Danish", command: () => "da" },
      { label: "Finnish", command: () => "fi" },
      { label: "Norwegian", command: () => "no" },
    ];

    const currentTranslation = computed(() => {
      return (
        props.modelValue?.translations?.find(
          (t) => t.languageCode === currentLanguage.value
        ) || { text: "" }
      );
    });

    const getFlagImage = (languageCode) => {
      const flags = {
        en: "https://flagcdn.com/w80/gb.png",
        da: "https://flagcdn.com/w80/dk.png",
        fi: "https://flagcdn.com/w80/fi.png",
        no: "https://flagcdn.com/w80/no.png",
      };
      return flags[languageCode] || flags.en;
    };

    const selectLanguage = (option) => {
      currentLanguage.value = option.command();
      const newText = currentTranslation.value?.text || "";
      editor.value.commands.setContent(newText, false);
      showDropdown.value = false;
    };

    const closeDropdown = () => {
      showDropdown.value = false;
    };

    const updateModelValue = () => {
      const newValue = { ...props.modelValue };
      const index = newValue.translations.findIndex(
        (t) => t.languageCode === currentLanguage.value
      );
      if (index !== -1) {
        newValue.translations[index].text = editor.value.getHTML();
      } else {
        newValue.translations.push({
          languageCode: currentLanguage.value,
          text: editor.value.getHTML(),
        });
      }
      emit("update:modelValue", newValue);
    };

    const editor = useEditor({
      content: props.modelValue?.translations?.find(
        (t) => t.languageCode == currentLanguage.value
      )?.text,
      extensions: [StarterKit, TextStyle, Underline, Color],
      editable: props.editButton ? false : true,
      onUpdate: () => updateModelValue(),
    });

    onMounted(() => {
      document.addEventListener("click", closeDropdown);

      if (!props.showHeaders && !props.blackTextColor)
        editor.value.chain().focus().setColor("#ffffff").run();
    });

    onUnmounted(() => {
      document.removeEventListener("click", closeDropdown);
    });

    return {
      editor,
      disabled,
      languageOptions,
      currentLanguage,
      getFlagImage,
      selectLanguage,
      showDropdown,
    };
  },
};
</script>
<style scoped>
.editorButton {
  border: none;
  background: none;
}
.editorButton:hover {
  background: lightblue;
}
.is-active {
  background: lightblue;
  border-radius: 2px;
}
.flag-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid var(--surface-border);
  overflow: hidden;
  background: var(--surface-card);
  cursor: pointer;
}
.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.language-dropdown {
  position: absolute;
  background: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 100;
}
.dropdown-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}
.menu-flag {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  object-fit: cover;
}
.ProseMirror:focus {
  outline: none;
}

.flag-button {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
  overflow: hidden;
  border: 1px solid var(--surface-border);
  background: var(--surface-card);
  cursor: pointer;
}

.flag-button:hover {
  background: var(--surface-hover);
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-flag {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--surface-border);
}

.language-dropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: var(--surface-overlay);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 1rem;
  min-width: 14rem;
  z-index: 1000;
}

.language-dropdown::before {
  content: "";
  position: absolute;
  top: -0.5rem;
  right: 1rem;
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--surface-overlay);
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0.5rem 0;
}

.dropdown-item:first-child {
  margin-top: 0;
}

.dropdown-item:last-child {
  margin-bottom: 0;
}

.dropdown-item:hover {
  background: var(--surface-hover);
  transform: translateX(2px);
}

.dropdown-item span {
  cursor: pointer;
  user-select: none;
}

.dropdown-item img {
  cursor: pointer;
  user-select: none;
}
</style>
