<template>
  <div class="field">
    <!-- <label :for="id" class="block text-900 font-medium mb-2">{{ title }}</label> -->
    <div class="flex gap-2 relative">
      <InputText
        :id="id"
        :value="currentTranslation?.text || ''"
        @input="updateText"
        class="flex-1"
        :class="{ 'p-invalid': error }"
      />
      <Button
        type="button"
        class="p-0 flag-button"
        @click="toggleDropdown"
      >
        <img
          :src="getFlagImage(currentLanguage)"
          :alt="currentLanguage"
          class="flag-image"
        />
      </Button>
      <div v-show="showDropdown" class="language-dropdown">
        <div 
          v-for="option in languageOptions" 
          :key="option.label"
          class="dropdown-item"
          @click="selectLanguage(option)"
        >
          <img :src="getFlagImage(option.command())" :alt="option.command()" class="menu-flag" />
          <span>{{ option.label }}</span>
        </div>
      </div>
    </div>
    <!-- <small v-if="help" class="block text-500 mt-1">{{ help }}</small> -->
    <!-- <small v-if="error" class="block text-red-500 mt-1">{{ error }}</small> -->
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  name: 'LocalizedTextField',
  components: {
    InputText,
    Button
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    help: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showDropdown = ref(false);
    const currentLanguage = ref('en');

    const languageOptions = [
      {
        label: 'English',
        command: () => 'en'
      },
      {
        label: 'Danish',
        command: () => 'da'
      },
      {
        label: 'Finnish',
        command: () => 'fi'
      },
      {
        label: 'Norwegian',
        command: () => 'no'
      }
    ];

    const currentTranslation = computed(() => {
      return props.modelValue?.translations?.find(t => t.languageCode === currentLanguage.value);
    });

    const getFlagImage = (languageCode) => {
      const flags = {
        en: 'https://flagcdn.com/w80/gb.png',
        da: 'https://flagcdn.com/w80/dk.png',
        fi: 'https://flagcdn.com/w80/fi.png',
        no: 'https://flagcdn.com/w80/no.png'
      };
      return flags[languageCode] || flags.en;
    };

    const updateText = (event) => {
      const newValue = { ...props.modelValue };
      const translationIndex = newValue.translations.findIndex(t => t.languageCode === currentLanguage.value);
      if (translationIndex !== -1) {
        newValue.translations[translationIndex].text = event.target.value;
        emit('update:modelValue', newValue);
      }
    };

    const toggleDropdown = (event) => {
      event.stopPropagation();
      showDropdown.value = !showDropdown.value;
    };

    const selectLanguage = (option) => {
      currentLanguage.value = option.command();
      showDropdown.value = false;
    };

    const closeDropdown = () => {
      showDropdown.value = false;
    };

    onMounted(() => {
      document.addEventListener('click', closeDropdown);
    });

    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown);
    });

    return {
      showDropdown,
      currentLanguage,
      languageOptions,
      currentTranslation,
      getFlagImage,
      updateText,
      toggleDropdown,
      selectLanguage
    };
  }
};
</script>

<style scoped>
.field {
  margin-bottom:0rem !important;
}

.flag-button {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0 !important;
  border-radius: 50% !important;
  overflow: hidden;
  border: 1px solid var(--surface-border);
  background: var(--surface-card);
  cursor: pointer;
}

.flag-button:hover {
  background: var(--surface-hover);
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-flag {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--surface-border);
}

.language-dropdown {
  position: absolute;
  top: calc(100% + 0.5rem);
  right: 0;
  background: var(--surface-overlay);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
  padding: 1rem;
  min-width: 14rem;
  z-index: 1000;
}

.language-dropdown::before {
  content: '';
  position: absolute;
  top: -0.5rem;
  right: 1rem;
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid var(--surface-overlay);
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0.5rem 0;
}

.dropdown-item:first-child {
  margin-top: 0;
}

.dropdown-item:last-child {
  margin-bottom: 0;
}

.dropdown-item:hover {
  background: var(--surface-hover);
  transform: translateX(2px);
}

.dropdown-item span {
  cursor: pointer;
  user-select: none;
}

.dropdown-item img {
  cursor: pointer;
  user-select: none;
}
</style> 